<template>
  <v-row no-gutters class="h-100">
    <v-col cols="12" offset="0" md="4" offset-md="4" class="d-flex flex-column justify-center align-center">
      <v-card class="w-100 rounded-xl py-4">
        <v-img contain height="80" class="ma-auto mt-4" src="/img/finpay-black.svg" />
        <v-card-text class="px-8">
          <v-form ref="loginForm" v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="form.email"
                  :placeholder="$t('login.email')"
                  persistent-placeholder
                  autocomplete
                  :label="$t('login.email')"
                  required
                  dense
                  single-line
                  validate-on-blur
                  :rules="emailRules"
                  @keyup.enter="submitHandle"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="form.password"
                  type="password"
                  autocomplete
                  :placeholder="$t('login.password')"
                  persistent-placeholder
                  name="input-10-1"
                  :label="$t('login.password')"
                  dense
                  single-line
                  validate-on-blur
                  :rules="passwordRules"
                  @keyup.enter="submitHandle"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-8 mb-4 d-flex justify-center">
          <v-btn
            color="primary"
            depressed
            :disabled="submitLoading"
            :loading="submitLoading"
            @click="submitHandle"
            rounded
            class="px-8"
          >
            {{ $t('login.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <div class="d-flex mt-4 flex-wrap text-center justify-center">
        <span class="mr-2">
          {{ $t('login.dontRememberPassword') }}
        </span>
        <span class="info--text pointer" @click="$router.push({ name: 'ResetPassword' })">
          {{ $t('login.changePassword') }}
        </span>
      </div>
    </v-col>
    <NewPassword ref="setNewPass" :email="form.email" :oldPassword="form.password" />
  </v-row>
</template>
<script>
import Service from '@/services'
import * as mutationTypes from '@/store/mutation-types'
import NewPassword from './NewPassword.vue'

export default {
  components: { NewPassword },
  name: 'Login',
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      submitLoading: false,
      valid: true,
      passwordRules: [(v) => !!v.trim() || this.$t('validation.passwordRequired')],
      emailRules: [
        (v) => !!v.trim() || this.$t('validation.emailRequired'),
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || this.$t('validation.incorrectEmailAddress')
      ]
    }
  },
  methods: {
    async getCountries () {
      try {
        const { data } = await Service.get('Tenants/?onlyCountries=true')
        this.$store.dispatch('setCountries', data)
      } catch (error) {
      }
    },
    async submitHandle () {
      if (!this.$refs.loginForm.validate()) {
        return
      }
      this.submitLoading = true
      try {
        const { data } = await Service.post('Employees/Login', {
          email: this.form.email,
          password: this.form.password
        })

        if (data.assignedCountry !== 'Global') {
          localStorage.setItem('tenantValue', data.assignedCountry)
          if (data.assignedCountry === 'CZ') {
            localStorage.setItem('currency', 'Kč')
          } else if (data.assignedCountry === 'HU') {
            localStorage.setItem('currency', 'Ft')
          } else {
            localStorage.setItem('currency', 'zł')
          }
        } else {
          localStorage.setItem('tenantValue', 'PL')
          localStorage.setItem('currency', 'zł')
        }

        if (data.token && data.setNewPasswordRequired) {
          localStorage.setItem('token', data.token)
          this.$refs.setNewPass.show()
        }

        if (data.token && !data.setNewPasswordRequired) {
          localStorage.setItem('token', data.token)
          this.$store.commit(mutationTypes.LOGIN, true)
          if (this.$store.getters.userRoleId === '3') {
            await this.$router.push({ path: '/cashiers-wrap' })
          } else {
            await this.$router.push({ path: '/orders-wrap' })
          }
          this.getCountries()
        }
        this.$refs.loginForm.resetValidation()
      } catch (e) {
      } finally {
        this.submitLoading = false
      }
    }
  }
}
</script>
